<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <button v-if="data.permission.canCreate"
                                        @click="modalOpen" class="btn btn-primary">
                                    Create New
                                </button>
                            </div>
                            <div class="expand-ctl">
                                <p>
                                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseProject-ctl" aria-expanded="false"
                                            aria-controls="collapseProject-ctl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                        </svg>
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="collapse mt-3" id="collapseProject-ctl">
                            <table-filter @change="getFilteredValue"/>
                        </div>
                    </div>
                    <data-table :options="dataList.options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @change-action="changeAction"
                        @pagination="pagination"
                        @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addGlbalDocumentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <add-edit-global-document 
                    v-if="dataList.isModalActive"
                    :form-error="dataList.formError"
                    :form-data="dataList.formData"
                    @submit-form="submitForm"/>
        </div>
    </div>
    <!-- /Modal -->
    </section>
</template>
<script>
import TableFilter from "@/common/components/table-filter/TableFilter";
import {useRoute} from "vue-router";
import {onMounted, reactive} from "vue";
import AddEditGlobalDocument from "@/views/auth/library/global-documents/AddEditGlobalDocument";
import DataTable from "@/common/components/datatable";
import HelperFunction from "@/common/helpers";

export default {
    name: "GlobalDocuments",
    components: {TableFilter,DataTable,AddEditGlobalDocument},
    setup() {
        const route = useRoute();
        const {data, getAllData, openModal, selectedCompany, storeFormData, deleteListData, permissionResolver} = HelperFunction();

        /** watch **/
        permissionResolver(route.path);

        let dataList = reactive({
            isModalActive: false,
            params: {
                page: null,
                limit: null,
                sort: 'desc',
                search: '',
                company_id: null,
            },
            formError: {},
            formData: {},
            options: {
                name: 'Global Documents',
                columns: [
                    {
                        title: 'Status',
                        type: 'text',
                        key: 'status',
                        tooltip: true
                    },
                    {
                        title: 'Type',
                        type: 'text',
                        key: 'type',
                        tooltip: false
                    },{
                        title: 'Project name',
                        type: 'text',
                        key: 'project_name',
                        tooltip: false
                    },{
                        title: 'Project leader',
                        type: 'text',
                        key: 'teamlead',
                        tooltip: false
                    },{
                        title: 'Date',
                        type: 'text',
                        key: 'start_date',
                        tooltip: false
                    }
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ]
            }
        })

        /** mounted**/
        onMounted(() => {
            getData();
            $('#showModal').on('hidden.bs.modal', (e) => {
                dataList.isModalActive = false;
                dataList.formData = {}
            });
        })
        /** mounted end**/
        const getData = () => {
            data.setURL = vueConfig.Library.globalContentItemsEndPoint;
            dataList.params.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            getAllData(data.setURL, dataList.params);
        }

        const changeAction = async (action, row) => {
            if (action.type === 'edit') {
                modalOpen();
                dataList.formData = row;
            } else if (action.type === 'delete') {
                await deleteListData(`${vueConfig.Library.globalContentItemsEndPoint}/${row.id}`)
                .then(() => {
                    getData()
                });
            }
        }
        const pagination = (page) => {
            dataList.params.page = page;
            getData();
        }
        const limitChange = (limit) => {
            dataList.params.limit = limit;
            delete dataList.params.page
            getData();
        }
        const submitForm = (formData) => {
            const action = {
                url: formData.id ? `${vueConfig.Library.globalContentItemsEndPoint}/${formData.id}` : vueConfig.Library.globalContentItemsEndPoint,
                modalId: 'showModal'
            };
            const submitData = {
                ...formData,
                company_id: selectedCompany.value.id ? selectedCompany.value.id : null,
                type: 'new',
                category: 1
            }
            storeFormData(action, submitData)
                .then(response => {
                    getData();
                    console.log(response, 'response');
                })
                .catch((error) => {
                    if (error.response) {
                        dataList.formError = error.response.data.errors;
                    }
                });
        }

        const modalOpen = () => {
            dataList.isModalActive = true;
            openModal('showModal');
        }

        function search() {
            getData();
        }
        /** watch end **/
        function getFilteredValue(params) {
            console.log(params);
        }

        return {
            data,
            pagination,
            limitChange,
            changeAction,
            dataList,
            search,
            getFilteredValue,
            modalOpen,
            submitForm
        }
    }
}
</script>

<style scoped>

</style>
