<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Global document</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <Form @submit="submitForm"
              :validation-schema="data.schema"
              v-slot="{ errors }">
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Select Project</label>
                        <Field
                            class="form-control"
                            name="document"
                            as="select"
                            v-model="data.selectedDocument"
                            @change="setName"
                        >
                            <option value="" disabled>Select Project</option>
                            <option
                                v-for="(doc, index) in documents"
                                :key="index"
                                :value="doc.id"
                            >
                                {{ doc.name }}
                            </option>
                        </Field>
                    </div>
                     <div class="col-12 mb-3">
                        <Field type="text"
                               name="project_name"
                               v-model="data.formData.project_name"
                               class="form-control form-control-sm"
                               readonly
                               placeholder="Project Name"/>
                        <span class="text-danger">{{ errors.project_name || serverFormError.project_name }}</span>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Team lead</label>
                        <autocomplete :list="items"
                                @autocomplete="search"
                                :set-search-name="data.formData.teamlead"
                                @selected-autocomplete="selectedAutocomplete"/>
                    </div>

                    <div class="col-12 mb-3">
                        <label class="form-control-label">Status</label>
                        <Field
                            type="text"
                            class="form-control form-control-sm"
                            name="status"
                            v-model="data.formData.status"
                            readonly
                            v-if="data.formData.id"
                        />
                        <Field
                            class="form-control"
                            name="status"
                            as="select"
                            v-model="data.formData.status"
                            v-if="!data.formData.id"
                            
                        >

                            <option value="" disabled>Select Status</option>
                            <option
                                v-for="(project,index) in data.projectStatus"
                                :key="index"
                                :value="project"
                                
                            >
                                {{ project }}
                            </option>
                        </Field>
                        <span class="text-danger">{{ errors.status || serverFormError.status }}</span>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Start date</label>
                        <Field
                            type="date"
                            name ="start_date"
                            v-model="data.formData.start_date"
                            class="form-control form-control-sm"
                            :min="minDateForExpiredAt"

                        />
                        <span class="text-danger">
                            {{ errors.start_date || serverFormError.start_date }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import {computed, reactive, watch, onMounted} from "vue";
    import * as Yup from "yup";
    import {useStore} from "vuex";
    import {Form, Field} from "vee-validate";
    import Autocomplete from "@/common/components/autocomplete/Autocomplete";
    import moment from "moment";

    export default {
        name: "AddEditGlobalDocument",
        props: {
            formData: {
                type: Object,
                required: false
            },
            formError: {
                type: Object,
                required: false
            }
        },
        components: {
            Form,
            Field,
            Autocomplete
        },
        emits: ['submitForm'],
        setup(props, {emit}) {
            let data = reactive({
                schema: Yup.object().shape({
                    project_name: Yup.string().required().label('Project Name'),
                    start_date: Yup.string().required().label('Start Date'),
                    status: Yup.string().required().label('Project Status'),

                }),
                formData: {
                    id: '',
                    project_name: '',
                    serial: '',
                    project_leader: '',
                    start_date: '',
                    status: '',
                    documentIds: [],

                },
                today : '',
                entries: [],
                selectedDocument: null,
                selectedteamLead: null,
                documentIds: [],
                projectStatus: [
                    'Active','Inactive','Completed','Canceled'
                ],
                selectedProjectLeader: {
                    email: ''
                },
            });
            const minDateForExpiredAt = computed(() => {
                return moment(data.formData.start_date)
                    .add(1, "days")
                    .format("YYYY-MM-DD");
            });
            const store = useStore();

            /** computed properties **/
            const serverFormError = computed(() => {
                let output = {};
                Object.keys(props.formError).forEach((key) => {
                    if (props.formError[key]) {
                        output[key] = props.formError[key][0]
                    }
                });
                return output;
            })
            /** watch properties **/

            /** watch properties **/
            watch(() => props.formData, (value) => {
                if (value) {
                    data.formData = {...data.formData, ...value};
                    if(value.teamlead){
                        const url = `${vueConfig.User.UsersFilterEndPoint}=${value.teamlead}`;
                        store.dispatch('getUsersByEmail',  {
                            'action' : url 
                        }).then(response => {
                            selectedAutocomplete(response.entries[0])
                        }).catch(error => {
                            console.log(error);
                        })
                    }
                    
                }
            }, {immediate: true})

            let search = (val) => {
                if(val != null){
                    const url = `${vueConfig.User.UsersFilterEndPoint}=${val}&companyUser=true`;
                    store.dispatch('getCompanyUser',  {
                        'action' : url 
                    }).then(response => {
                        const { count, entries } = response
                        data.entries = entries
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
            /** watch properties end **/

            /** Methods **/
            const submitForm = () => {
                if (data.formData.id) {
                    data.formData._method = 'PUT';
                }
                data.formData.project_leader = data.selectedProjectLeader
                emit('submitForm', data.formData);
            }

            const selectedAutocomplete = (user) => {
                    console.log(user);
                    data.selectedProjectLeader = {
                        ...user,
                        email: user.email,
                        id: user.id,
                        rational: user.searchRational ? user.searchRational : null,
                        title: user.name,
                    };
            }

            const items = computed(() => {
                return data.entries.map(entry => {
                    return Object.assign({}, entry)
                })
            })
            let documents = computed(() => store.getters.getDocuments);

            let setName= () => {
                let name = documents.value.filter(row => row.id === data.selectedDocument).map(row => row.name)
                data.formData.documentIds.push(data.selectedDocument)
                data.formData.project_name = name[0]
            }
            /** Methods end**/

            return {
                data,
                submitForm,
                serverFormError,
                documents,
                items,
                setName,
                search,
                minDateForExpiredAt,
                selectedAutocomplete
            }
        }

    }
</script>

